import React, { useContext, useEffect } from "react";
import CustomPriceContext from "../customPriceContext";
import Gallery from "./components/gallery";
import RelatedProducts from "./components/relatedProducts";
import Toolbar from "../components/toolbar";
import Options from "../components/options";
import Specs from "../components/specs";
import ProductCMS from "../components/cms";
import Breadcrumbs from "app/layout/breadcrumbs";
import { useMediaQuery } from "react-responsive";
import RoomScenes from "./RoomScenes";
import useTagboxHelper from "app/layout/Tagbox/useTagboxHelper";
import Price from "core/components/price";
import RightSide from "./components/rightSide/rightSide";
import Button from "core/components/button";
import CmsBlock from "app/layout/cmsBlock";
import RecentlyViewed from "./components/recentlyViewed";

const GenericPDP = ({
  product,
  loading,
  optionState,
  optionDispatch,
  availableIn,
  mappedSpecs,
  relatedProducts,
  calculateOptions,
  downloadTearSheet,
  isEdit,
  finishError,
  setFinishError,
  ...props
}) => {
  const {
    state: { price },
    dispatch: priceDispatch,
  } = useContext(CustomPriceContext);
  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });

  const { tag } = useTagboxHelper();

  useEffect(() => {
    priceDispatch({
      type: "setMinPrice",
      minPrice: product?.price_range?.minimum_price?.final_price?.value || 0,
    });
  }, [priceDispatch, product?.price_range?.minimum_price?.final_price?.value]);

  function generateCollectionName(collections) {
    return collections?.length > 0 && collections[0] !== "false"
      ? collections.join(",")
      : "";
  }
  function isPelleCollection() {
    let isPelle = false;
    if (product?.collection) {
      product.collection.forEach((col) => {
        if (col.toLowerCase() === "pelle provisions") {
          isPelle = true;
        }
      });
    }
    return isPelle;
  }

  return (
    <>
      <div className={"generic-pdp"}>
        <div className="container-fluid">
          <Breadcrumbs className={"toolbar-breadcrumbs"} sku={product?.sku} />
          <div className={`row pdp-main-container`}>
            <div className="pdp-gallery-wrapper col-lg-6 col-xl-7">
              <Gallery gallery={product.media_gallery} hideLabel={true} />
            </div>
            <div className="col-lg-5 col-xl-4">
              <div className={"generic-options-container"}>
                {!isMobileOrTablet ? (
                  <div className={`generic-left`}>
                    <RightSide
                      mappedSpecs={mappedSpecs}
                      price={price}
                      product={product}
                      downloadTearSheet={downloadTearSheet}
                      isCustom={false}
                      optionState={optionState}
                      calculateOptions={calculateOptions}
                      isEdit={isEdit}
                      setFinishError={setFinishError}
                      options={product.options}
                      optionDispatch={optionDispatch}
                      productImage={product.image}
                      sku={product.sku}
                      leatherAvailable={product.available_in_leather}
                      isPelleProvisions={isPelleCollection()}
                      ffi_fullyuph={product?.ffi_fullyuph}
                      finishOption={product.allowed_finishes}
                      customName={generateCollectionName(product.collection)}
                      finishError={finishError}
                    />
                  </div>
                ) : (
                  <div className={"generic-right"}>
                    <Options
                      options={product.options}
                      optionState={optionState}
                      optionDispatch={optionDispatch}
                      productImage={product.image}
                      sku={product.sku}
                      leatherAvailable={product.available_in_leather}
                      isPelleProvisions={isPelleCollection()}
                      ffi_fullyuph={product?.ffi_fullyuph}
                      finishOption={product.allowed_finishes}
                      product={product}
                      customName={generateCollectionName(product.collection)}
                      finishError={finishError}
                      setFinishError={setFinishError}
                      priceDispatch={priceDispatch}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {isMobileOrTablet && (
          <Toolbar
            sku={product.sku}
            name={product.name}
            calculateOptions={calculateOptions}
            downloadTearSheet={downloadTearSheet}
            id={product.id}
            image={product.image}
            product={product}
            isEdit={isEdit}
            optionState={optionState}
            availableIn={availableIn}
            customName={generateCollectionName(product.collection)}
            description={product.ffi_extdesc2}
            finishError={finishError}
            setFinishError={setFinishError}
          />
        )}

        <RelatedProducts relatedProducts={relatedProducts} />
        {/* {tag} */}
        {/* <div className="browse-residential-commercial-lookbooks-wrap mb-20">
          <CmsBlock id="browse-residential-commercial-lookbooks" />
        </div> */}

        <CmsBlock id="inspirations-lookbook-pdp" />

        <RoomScenes product={product} />
        {isMobileOrTablet && tag}
        {isMobileOrTablet && <RoomScenes product={product} />}
      </div>
      {!isMobileOrTablet && (
        <>
          <div className="browse-residential-commercial-lookbooks-wrap">
            <CmsBlock id="browse-residential-commercial-lookbooks" />
          </div>
          <RecentlyViewed />
          <div className="exploration-wrapper">
            <CmsBlock id="explore-installation-gallery" />
          </div>
        </>
      )}
      {isMobileOrTablet && <ProductCMS />}
    </>
  );
};

export default GenericPDP;
